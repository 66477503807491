import { render, staticRenderFns } from "./DataCiteWidget.vue?vue&type=template&id=2ee9344a&scoped=true&"
import script from "./DataCiteWidget.vue?vue&type=script&lang=js&"
export * from "./DataCiteWidget.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./DataCiteWidget.vue?vue&type=style&index=0&id=2ee9344a&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2ee9344a",
  null
  ,true
)

export default component.exports