var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a',{attrs:{"href":_vm.link}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"p-2"},[_c('v-badge',{staticClass:"align-self-center",scopedSlots:_vm._u([(['', '0', 0].includes(_vm.citations) == false)?{key:"badge",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.formatNumbers(_vm.citations))+"\n            ")]},proxy:true}:null],null,true)},[_c('citationIcon',{class:['', '0', 0].includes(_vm.citations) == false
                  ? _vm.activeClass
                  : _vm.inactiveClass})],1)],1),_c('div',{staticClass:"p-2"},[_c('v-badge',{staticClass:"align-self-center",scopedSlots:_vm._u([(['', '0', 0].includes(_vm.views) == false)?{key:"badge",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.formatNumbers(_vm.views))+"\n            ")]},proxy:true}:null],null,true)},[_c('viewIcon',{class:['', '0', 0].includes(_vm.views) == false
                  ? _vm.activeClass
                  : _vm.inactiveClass})],1)],1),_c('div',{staticClass:"p-2"},[_c('v-badge',{staticClass:"align-self-center",scopedSlots:_vm._u([(['', '0', 0].includes(_vm.downloads) == false)?{key:"badge",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.formatNumbers(_vm.downloads))+"\n            ")]},proxy:true}:null],null,true)},[_c('downloadIcon',{class:['', '0', 0].includes(_vm.downloads) == false
                  ? _vm.activeClass
                  : _vm.inactiveClass})],1)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"p-2"},[_c('p',{class:['', '0', 0].includes(_vm.citations) == false
              ? _vm.labelActiveClass
              : _vm.labelInactiveClass},[_vm._v("\n          Citations\n        ")])]),_c('div',{staticClass:"p-2"},[_c('p',{class:['', '0', 0].includes(_vm.views) == false
              ? _vm.labelActiveClass
              : _vm.labelInactiveClass},[_vm._v("\n          Views\n        ")])]),_c('div',{staticClass:"p-2"},[_c('p',{class:['', '0', 0].includes(_vm.downloads) == false
              ? _vm.labelActiveClass
              : _vm.labelInactiveClass},[_vm._v("\n          Downloads\n        ")])])]),_c('div',{staticClass:"row justify-content-end"},[_c('logo',{class:'svglogo'})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }